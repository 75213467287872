import * as React from 'react'
import { Link } from 'gatsby'

import Seo from '../../common/seo'
import { AuthFlowWrapper } from '../layout'
import { LayoutLoggedIn } from '../layout'
import { isLoggedIn } from '../../../common/utils/auth'
import { Paths } from '../../../constants/structure'
import { ErrorViewProps } from './types'

const NotFoundMessage = ({ isLoggedIn = false }: ErrorViewProps) => (
   <div>
      <Seo title="Page not found" />
      {isLoggedIn && <h1>Page not found</h1>}
      <p>The page you are looking for is not available. This may be because:</p>
      <ul>
         <li>it is out of date and has been deleted;</li>
         <li>it has moved; or</li>
         <li>the webpage address was entered incorrectly.</li>
      </ul>
      <Link to={isLoggedIn ? Paths.DASHBOARD : Paths.LANDING}>
         Return to home
      </Link>
   </div>
)

const NotFoundView = () => {
   const Layout1 = () => (
      <LayoutLoggedIn>
         <NotFoundMessage isLoggedIn />
      </LayoutLoggedIn>
   )

   const Layout2 = () => (
      <AuthFlowWrapper heading={<>Page not found</>}>
         <NotFoundMessage />
      </AuthFlowWrapper>
   )

   return <>{isLoggedIn() ? Layout1() : Layout2()}</>
}

export default NotFoundView
